import { DEFAULT_CDN_URL, FEAASCDNStylesheetParams, parseStylesheetSource } from '../cdn.js'
import { loadStylesheet } from '../dom/stylesheet.js'
import { FEAASElement } from './FEAASElement.js'

export function FEAASStylesheetProps(element: FEAASElement) {
  const src = element.getAttribute('src')
  if (src) {
    var { cdn, library, revision } = parseStylesheetSource(src)
  }
  return {
    library: element?.getContextAttribute('library'),
    revision: element?.getAttribute('revision') ?? revision ?? 'published',
    cdn: element?.getContextAttribute('cdn') ?? element?.getContextAttribute('hostname') ?? DEFAULT_CDN_URL
  } as FEAASCDNStylesheetParams
}
export type FEAASStylesheetProps = ReturnType<typeof FEAASStylesheetProps>

export class FEAASStylesheet extends FEAASElement<FEAASStylesheetProps, string> {
  getProps() {
    return FEAASStylesheetProps(this)
  }

  isReadyToLoad(): boolean {
    return this.props.library != null && this.props.revision != null && this.props.cdn != null
  }

  onLoad = (html: string) => {
    this.payload = html
    this.advance()
  }

  load() {
    return loadStylesheet(this.props, this.onLoad)
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-stylesheet': Partial<FEAASStylesheetProps> & { children?: any }
    }
  }
}
FEAASStylesheet.register('feaas-stylesheet')
export { FEAASStylesheet as Stylesheet }
