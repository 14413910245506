const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const formatDate = (date: Date) => {
  if (!date || typeof date.getDay !== 'function') return

  const year = date.getFullYear()
  const month = monthNames[date.getMonth()]
  const day = date.getDate()

  return `${month} ${day}, ${year}`
}

export const isDate = function (date: string) {
  return String(new Date(date)) !== 'Invalid Date' && !isNaN(Number(new Date(date)))
}
