import { FEAASElement } from './FEAASElement.js'

export function FEAASLoaderProps(element: HTMLElement) {
  return {
    opacity: element?.getAttribute('opacity') ? parseFloat(element?.getAttribute('opacity')) : 1,
    hidden: element?.getAttribute('hidden') != null
  }
}
type FEAASLoaderProps = ReturnType<typeof FEAASLoaderProps>

export class FEAASLoader extends FEAASElement<FEAASLoaderProps, string> {
  stylesheet: HTMLStyleElement
  loader: HTMLDivElement

  getProps() {
    return FEAASLoaderProps(this)
  }

  defaultProps = {
    hidden: false
  } as Partial<FEAASLoaderProps>

  static observedAttributes = ['hidden', 'opacity']

  attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return
    if (name == 'hidden') {
      this.style.setProperty('display', 'block', 'important')
      this.style.setProperty('transition', 'opacity 0.3s', 'important')
      if (newValue) {
        this.style.setProperty('opacity', '0', 'important')
        this.style.pointerEvents = 'none'
      } else {
        this.style.setProperty('opacity', '1', 'important')
        this.style.pointerEvents = 'all'
      }
    }
    return super.attributeChangedCallback(name, oldValue, newValue)
  }

  connectedCallback() {
    this.payload = null
    super.connectedCallback()
    this.attributeChangedCallback('hidden', this.getAttribute('hidden'), this.getAttribute('hidden'))
    this.stylesheet = document.createElement('style')
    this.stylesheet.textContent = `
  #loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6ff;
    z-index: 1000;
  }
  @keyframes loader-circular-track {
    0% {
      stroke-dasharray: 1, 400;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 400, 400;
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dasharray: 400, 400;
      stroke-dashoffset: -260;
    }
  }
  
  @keyframes loader-circular-spinner {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  #loader .spinner {
    width: 48px;
    height: 48px;
  }
  `
    this.loader = document.createElement('div')
    this.loader.id = 'loader'
    this.loader.innerHTML = `
  <div class="spinner">
    <svg
      viewBox="0 0 100 100"
      style="
        animation: 2s linear 0s infinite normal none running loader-circular-spinner;
        display: block;
        width: 48px;
        height: 48px;
      "
    >
      <circle fill="transparent" cx="50" cy="50" r="42" stroke="rgba(0,0,0,0.1)" stroke-width="10px"></circle>
      <circle
        fill="transparent"
        stroke="#5548D9"
        cx="50"
        cy="50"
        r="42"
        stroke-width="10px"
        stroke-linecap="round"
        style="animation: 1.5s linear 0s infinite normal none running loader-circular-track"
      ></circle>
    </svg>
  </div>`
    this.getRoot().appendChild(this.stylesheet)
    this.getRoot().appendChild(this.loader)
  }

  update() {
    this.loader.style.opacity = String(this.props.opacity)
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-loader': any
    }
  }
}
FEAASLoader.register('feaas-loader')

export { FEAASLoader as Loader }
