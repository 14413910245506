import type * as FEAASContextImports from '@sitecore-feaas/frontend/context'
import type { AuthModel } from '@sitecore-feaas/sdk'
import { FEAASElement, OptionalExcept } from '../components/FEAASElement.js'
import { ControlledPromise } from '../utils/promise.js'
import type * as FEAAS from './index.js'

export function FEAASContextProps(element: HTMLElement) {
  return {
    library: element?.getAttribute('library') as string,
    src: element?.getAttribute('src') ?? '/context.js',
    // override environment
    environment: element?.getAttribute('environment') ?? undefined,
    frontend: element?.getAttribute('frontend') ?? 'https://components.sitecorecloud.io',
    backend: element?.getAttribute('backend') ?? 'https://components.sitecorecloud.io/api',
    cdn: element?.getAttribute('cdn') ?? 'https://feaas.blob.core.windows.net',
    auth: null as Partial<AuthModel>,
    isolate: element?.getAttribute('isolate') == null || element?.getAttribute('isolate') == 'true'
  }
}
export type FEAASContextProps = ReturnType<typeof FEAASContextProps>

/**
 * Context provides shared parameters and react providers for its nested elements. It loads lazily once any of its
 * nested components load. Just placing it on the page without any consumers does not impose any overhead.
 */
export class FEAASContext extends FEAASElement<FEAASContextProps, typeof FEAASContextImports> {
  static observedAttributes = ['library', 'component', 'version', 'revision', 'hostname', 'template']
  getProps() {
    return FEAASContextProps(this)
  }

  defaultProps = {
    isolate: true
  } as Partial<FEAASContextProps>

  load() {
    return this.import(this.formatURL(this.props.src, this.props.frontend))
  }

  isReadyToLoad() {
    return super.isReadyToLoad() && this.props.library != null
  }

  // Remove global styles
  getTheme(theme: FEAASContextImports.Chakra.Theme): FEAASContextImports.Chakra.Theme {
    return theme
  }

  getRoot(): HTMLElement | FEAASElement<any, any> | ShadowRoot {
    if (this.getAttribute('isolate') === 'false') return this
    return this.shadowRoot || this.attachShadow({ mode: 'open' })
  }

  reactRootElement: HTMLDivElement
  getReactRootElement() {
    //if (!this.props.isolate) return this.getRoot()
    if (!this.reactRootElement) {
      this.reactRootElement = document.createElement('div')
      this.getRoot().appendChild(this.reactRootElement)
    }
    return this.reactRootElement
  }

  /** Pull in children FEAAS react elements into the shadow DOM so they receieve the styles */
  connectedCallback() {
    this.setAttribute('feaas-role', 'ui')
    //if (this.props.isolate)
    Array.from(this.children).forEach((child) => {
      this.getRoot().appendChild(child)
    })
    document.addEventListener('feaasMount', this)
    document.addEventListener('feaasUnmount', this)
    super.connectedCallback()
  }
  disconnectedCallback() {
    document.removeEventListener('feaasMount', this)
    document.removeEventListener('feaasUnmount', this)
    super.disconnectedCallback()
  }

  sdk: FEAASContextImports.SDK
  clientside: typeof FEAAS
  onStatusChange: FEAASContextImports.SDKStatusCallback = (status, sdk) => {
    if (status == 'ready') {
      this.whenSDKReady.resolve(sdk)
      this.dispatchEvent(new CustomEvent('feaasReady', { bubbles: true, composed: true, detail: sdk }))
    }
  }
  whenSDKReady = ControlledPromise<FEAASContextImports.SDK>((sdk) => {
    this.sdk = sdk
  })

  getPortalRoot() {
    return this.getRoot()
  }

  render(
    { children, library }: React.PropsWithChildren<FEAASContextProps>,
    { AppContext, Chakra: { Button, Tooltip }, React }: typeof this.payload
  ) {
    const portalProps = {
      containerRef: { current: this.getPortalRoot() as HTMLElement }
    }
    Tooltip.defaultProps = {
      hasArrow: true,
      placement: 'top',
      portalProps
    }
    return (
      <AppContext
        environment={this.props.environment as any}
        backend={this.props.backend}
        libraryId={this.props.library}
        container={this.getRoot()}
        auth={this.props.auth}
        getTheme={this.getTheme}
        onStatusChange={this.onStatusChange}
        modalProps={{
          portalProps
        }}
        chakraProps={{
          resetScope: ':where([feaas-role="ui"])',
          cssVarsRoot: ':host',
          resetCSS: true,
          toastOptions: {
            portalProps
          }
        }}
      >
        {children}
      </AppContext>
    )
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-context': OptionalExcept<FEAASContextProps, 'library'>
    }
  }
  interface DocumentEventMap {
    feaasReady: CustomEvent<FEAASContextImports.SDK>
  }
}

export { FEAASContext as Context }
FEAASContext.register('feaas-context')
