import type * as FEAASPickerImports from '@sitecore-feaas/frontend/picker'
import { FEAASElement } from '../components/FEAASElement.js'

export function FEAASPickerProps(element: FEAASElement<any, any>) {
  return {
    part: element?.getAttribute('part') || 'picker',
    slot: element?.getAttribute('part') || 'picker',
    mode: element?.getAttribute('mode') as FEAASPickerImports.PickerProps['mode'],
    dialog: element?.getAttribute('dialog') as FEAASPickerImports.PickerProps['dialog'],
    placeholder: element?.getAttribute('placeholder') || 'No theme',
    frontend: element?.getContextAttribute('frontend') ?? 'https://components.sitecorecloud.io',
    src: element?.getAttribute('src') ?? '/picker.js',
    hidden: element?.getAttribute('hidden') != null,
    variant: element?.getAttribute('variant') as 'internal' | 'pages',
    context: undefined as FEAASPickerImports.PickerProps['context'],
    onClose: undefined as (...args: any[]) => void,
    setAttribute: undefined as FEAASPickerImports.AttributeSetter,
    getAttribute: undefined as FEAASPickerImports.AttributeGetter
  }
}
export type FEAASPickerProps = ReturnType<typeof FEAASPickerProps>

export class FEAASPicker extends FEAASElement<FEAASPickerProps, typeof FEAASPickerImports> {
  static observedAttributes = ['library', 'mode', 'frontend', 'src', 'hidden', 'variant', 'dialog']

  defaultProps = {
    hidden: false
  } as Partial<FEAASPickerProps>

  getProps() {
    return FEAASPickerProps(this)
  }

  load() {
    return this.import(this.formatURL(this.props.src, this.props.frontend))
  }
  connectedCallback() {
    this.setAttribute('feaas-role', 'ui')
    super.connectedCallback()
  }
  render(
    { placeholder, context, mode, dialog, children, variant }: React.PropsWithChildren<FEAASPickerProps>,
    { Picker, React, useContext, EnvironmentContext, useLibrary, useSDK, BYOC }: typeof FEAASPickerImports
  ) {
    const [renders, forceRender] = React.useReducer((c) => c + 1, 0)
    const sdk = useSDK()
    const env = useContext(EnvironmentContext)
    const collections = useLibrary('collections')
    const components = useLibrary('components')
    const datasources = useSDK('datasources')
    const library = useLibrary()
    // @ts-ignore temporary workaround until we release all environments
    const rules = library.stylesheets?.first?.rules || library?.stylesheet?.styles
    const externalComponents = useSDK('externalComponents')
    var settings = context?.getAttribute('data')
    if (settings) {
      try {
        settings = JSON.parse(settings)
      } catch (e) {}
    }
    if (context) {
      var embedDefinition = sdk.ExternalComponent.getDefinition(context)
      var isUnconfigured = embedDefinition && !embedDefinition.getConfiguration(context)
      var externalComponent =
        embedDefinition?.type == 'feaas'
          ? components.find((c) => c.id == context.getAttribute('component'))
          : BYOC?.getComponent(String(context.getAttribute('data-external-id')))
    }
    return (
      <Picker
        cdn={env.cdn}
        rules={rules}
        collections={collections}
        themeContext={context}
        placeholder={placeholder}
        embed={externalComponent}
        externalComponents={externalComponents}
        datasources={datasources}
        data={settings}
        versionId={context?.getAttribute('version')}
        mode={mode || (isUnconfigured ? 'library' : externalComponent ? 'context' : null)}
        setAttribute={(context, attribute, value) => {
          if (attribute == 'data' && value) value = JSON.stringify(value)
          this.props.setAttribute(context, attribute, value)
          forceRender()
        }}
        getAttribute={(c, property) => context?.getAttribute(property)}
        dialog={dialog}
        context={context}
        variant={variant}
        onModeChange={(mode, dialog) => {
          this.set({ mode, dialog })
        }}
      >
        {children}
      </Picker>
    )
  }
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-picker': Partial<typeof FEAASPickerImports>
    }
  }
}

export { FEAASPicker as Picker }
FEAASPicker.register('feaas-picker')
