import { JSONPath } from 'jsonpath-plus'

export function queryObjectRaw(json: any, path: string) {
  try {
    return JSONPath<any[]>({ path, json })
  } catch (e) {
    return []
  }
}
export function queryObject(json: any, path: string, flatten = true) {
  const data = queryObjectRaw(json, path) || []
  if (flatten) {
    return data.flat(Infinity)
  }
  return data
}

export function queryScopes(scopes: any, datapath: string, singleValue = false, json: any = scopes, flatten = true) {
  var path = datapath
  /* find closest parent scope */
  const keys = Object.keys(scopes).sort((b, a) => a.length - b.length)
  for (const scope of keys) {
    if (datapath.startsWith(scope) && 'ends with it') {
      if (scope == datapath) {
        if (singleValue) {
          return scopes[scope]
        } else {
          continue
        }
      }
      path = datapath.substring(scope.length + 1)
      json = scopes[scope]
      break
    }
  }

  var collection = queryObject(json, path, flatten)

  // If regular path didn't match any data, try fallbacks
  // datasourceId.wrapper.$.path
  if (collection.length == 0) {
    collection = [
      // look up in `_` datasource id (catch-all id is used in JSS)
      //   _.wrapper.$.path
      path.replace(/^.*?\./, '_.'),
      // look up directly on data root
      //   wrapper.$.path
      path.replace(/^.*?\./, ''),
      // look up trimmed path in datasource
      //   datasourceId.path
      path.replace(/^([^.]+).*?\.\$([\.\[])/, '$1$2'),
      // look up trimmed path in catch all datasource
      //   _.path
      path.replace(/^.*?\.\$([\.\[])/, '_$1'),
      // look up trimmed path on root
      // path
      path.replace(/^.*?\.\$([\.\[])/, '$$$1'),
      // for case of top-level object data mapping full path can be replaced to _
      path.replace(/^[^.]+$/, '_')
    ]
      .filter((v, i, a) => a.indexOf(v) === i)
      .reduce((v, path) => {
        return v.length > 0 ? v : queryObject(json, path, flatten)
      }, [] as any[])
  }

  if (singleValue) {
    return collection[0]
  } else {
    return collection
  }
}

export type CH1Node =
  | {
      type: string
      text?: string
      content?: CH1Node[]
      marks?: CH1Node[]
      attrs?: Record<string, any>
    }
  | CH1Node[]
function serializeCH1Tag(data: any, attrs: Record<string, string> = {}, tag: string): string {
  if (!tag) return serializeCH1RichText(data)
  return `<${tag}${Object.entries(attrs)
    .map(([k, v]) => v != null && ` ${k}="${v}"`)
    .filter(Boolean)
    .join('')}>${serializeCH1RichText(data)}</${tag}>`.replace(/^<([a-z0-9][^>]*)><\/[^>]+>$/, '<$1 />')
}
export function serializeCH1RichText(data: CH1Node | string | null | undefined): string {
  if (Array.isArray(data)) return data.map(serializeCH1RichText).join('\n').trim()
  if (typeof data == 'string') return data
  if (data == null) return ''
  switch (data.type) {
    case 'heading':
      const { level, ...attrs } = data.attrs
      return serializeCH1Tag(data.content, attrs, 'h' + level)
    case 'text':
      return ((data.marks || []) as any[]).reduce((text, mark) => {
        return serializeCH1Tag(
          text,
          mark.attrs,
          {
            link: 'a',
            bold: 'strong',
            italic: 'em',
            strike: 's',
            underline: 'u'
          }[mark.type as string]
        )
      }, data.text?.replace(/\s+/, ' '))
    default:
      return serializeCH1Tag(
        data.content || data.text,
        data.attrs,
        {
          bulletList: 'ul',
          orderedList: 'ol',
          listItem: 'li',
          horizontalRule: 'hr',
          paragraph: 'p',
          codeBlock: 'code',
          blockquote: 'blockquote'
        }[data.type]
      )
  }
}

export { JSONPath }
