import { fetchAndRevalidateComponent } from '../cdn.js'
import { DataScopes } from '../utils/settings.js'
import { FEAASComponentProps, renderDOMContent, renderDOMElement } from './rendering.js'

export function renderComponentElement(content: string, datasources?: DataScopes) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = content
  const fragment = document.createDocumentFragment()
  while (wrapper.firstChild) {
    fragment.appendChild(wrapper.firstChild)
  }
  renderDOMElement(fragment, datasources)
  return wrapper
}

export function renderComponent(props: FEAASComponentProps, root?: HTMLElement) {
  renderComponentPromise(props, (root ||= document.createElement('div')))
  return root
}

export async function renderComponentPromise(props: FEAASComponentProps, root?: HTMLElement) {
  root ||= document.createElement('div')
  if (!root.childNodes.length) {
    if ('template' in props) {
      return renderDOMContent(root, props.template, props.data, props)
    } else {
      return fetchAndRevalidateComponent(props, (template) => {
        return renderDOMContent(root, template, props.data, props)
      }).then(() => root)
    }
    /* update element with new data */
  } else {
    return renderDOMElement(root, props.data, props)
  }
}
