/**
 * Simplified build of clientside that does not rely on any Frontend UI (like pickers). It refered from Frontend app to
 * avoid circular dependency. Regular clientside build entry point is in ui/index.ts
 */

import FEAASReactElement from './components/FEAASReactElement.js'

export * from './dom/rendering.js'
export * from './utils/data.js'
export * from './dom/scripts.js'
export * from './dom/stylesheet.js'
export * from './dom/component.js'
export * from './fetch.js'
export * from './utils/promise.js'
export * from './cdn.js'
export * from './components/FEAASElement.js'
export * from './components/FEAASReactElement.js'
export * from './components/FEAASComponent.js'
export * from './components/FEAASLoader.js'
export * from './components/FEAASStylesheet.js'
export * from './utils/datapath.js'
export * as DOM from './dom/rendering.js'
export * as DataScope from './utils/data.js'
export * as DataPath from './utils/datapath.js'
export { DataSettings, fetchData } from './utils/settings.js'
export type { Data, DataOptionsById, DataOptions, DataScopes } from './utils/settings.js'
export * as Thumbnail from './dom/thumbnail.js'

declare global {
  interface Window {
    FEAASLoading: Promise<any>
    FEAASReactElement: typeof FEAASReactElement
  }
}

if (typeof window != 'undefined') {
  window.FEAASReactElement = FEAASReactElement
  window.FEAASLoading ??= Promise.resolve()
}
