import type * as FEAASEditorImports from '@sitecore-feaas/frontend/editor'
import { FEAASElement, OptionalExcept } from '../components/FEAASElement.js'
import { FEAASLoader } from '../components/FEAASLoader.js'

export function getFramePosition(element: HTMLElement) {
  let positions = []
  if (element) {
    let currentWindow = element.ownerDocument.defaultView as Window
    let currentParentWindow
    let rect
    while (currentWindow !== window.top) {
      currentParentWindow = currentWindow.parent
      for (let idx = 0; idx < currentParentWindow.frames.length; idx++)
        if (currentParentWindow.frames[idx] === currentWindow) {
          for (let frameElement of currentParentWindow.document.getElementsByTagName('iframe')) {
            if (frameElement.contentWindow === currentWindow) {
              rect = frameElement.getBoundingClientRect()
              const style = frameElement.contentWindow.getComputedStyle(frameElement)

              positions.push({
                left: rect.left + parseInt(style.borderLeftWidth),
                top: rect.top + parseInt(style.borderTopWidth)
              })
            }
          }
          currentWindow = currentParentWindow as Window
          break
        }
    }
  }
  return positions.reduce(
    (accumulator, currentValue) => {
      return {
        left: accumulator.left + currentValue.left,
        top: accumulator.top + currentValue.top
      }
    },
    { left: 0, top: 0 }
  )
}
export function FEAASEditorProps(element: FEAASElement) {
  return {
    context: HTMLElement,
    frontend: element?.getContextAttribute('frontend'),
    src: element?.getAttribute('src') ?? '/editor.js',
    library: element?.getAttribute('library'),
    component: element?.getAttribute('component'),
    version: element?.getAttribute('version'),
    instance: element?.getAttribute('instance'),
    forked: element?.getAttribute('forked'),
    revision: element?.getAttribute('revision') || 'saved',
    hidden: element?.getAttribute('hidden') != null,
    for: element?.getAttribute('for'),
    state: element?.getAttribute('state') || ('idle' as 'idle' | 'loading' | 'ready'),
    changed: element?.getAttribute('changed') != null
  }
}
type FEAASEditorProps = ReturnType<typeof FEAASEditorProps>
type Rect = {
  top: number
  left: number
  width: number
  height: number
}

export class FEAASEditor extends FEAASElement<FEAASEditorProps, typeof FEAASEditorImports> {
  framePoint: Pick<Rect, 'top' | 'left'> = { top: 0, left: 0 }
  wrapperRect: Rect = { top: 0, left: 0, width: 0, height: 0 }
  bodyRect: Rect = { top: 0, left: 0, width: 0, height: 0 }

  static observedAttributes = ['library', 'component', 'version', 'revision', 'hidden', 'state', 'changed', 'forked']

  defaultProps = {
    hidden: false
  } as Partial<FEAASEditorProps>

  getProps() {
    return FEAASEditorProps(this)
  }
  reactRootElement: HTMLDivElement
  getReactRootElement() {
    if (!this.reactRootElement) {
      this.reactRootElement = document.createElement('div')
      this.getRoot().appendChild(this.reactRootElement)
    }
    return this.reactRootElement
  }

  target: HTMLElement
  setTarget = (
    element: HTMLElement,
    props = {
      component: element.getAttribute('component'),
      version: element.getAttribute('version'),
      revision: element.getAttribute('revision'),
      instance: element.getAttribute('instance')
    }
  ) => {
    if (!this.closing && (!this.target || element == null)) {
      this.set(props)
      this.target = element
      return true
    }
  }

  showLoader() {
    if (!this.loader) {
      this.loader = document.createElement('feaas-loader') as FEAASLoader
      this.loader.setAttribute('opacity', '0.6')
      this.loader.style.zIndex = '1000'
      this.target.appendChild(this.loader)
      if (this.target.tagName == 'FEAAS-COMPONENT') {
        this.target.style.position = 'relative'
      }
    }
  }

  hideLoader() {
    if (this.loader) {
      this.loader.setAttribute('hidden', 'hidden')
      if (this.target.tagName == 'FEAAS-COMPONENT') {
        this.target.style.position = 'relative'
        this.target.style.position = ''
      }
    }
  }

  load() {
    return import(/* @vite-ignore */ /* webpackIgnore: true */ this.formatURL(this.props.src, this.props.frontend))
  }

  onChromeMeasureElement = (element: HTMLElement, rect: Rect = element.getBoundingClientRect()) => {
    if (element.ownerDocument == document) {
      return {
        left: rect.left - this.bodyRect.left,
        top: rect.top - this.bodyRect.top,
        width: rect.width,
        height: rect.height
      }
    }
    return {
      left: rect.left + this.framePoint.left - this.bodyRect.left,
      top: rect.top + this.framePoint.top - this.bodyRect.top,
      width: rect.width,
      height: rect.height
    }
  }

  onBeforeMeasure: FEAASEditorImports.EditorContext['onChromeBeforeMeasure'] = (refs) => {
    if (refs.memoized.focusable) this.framePoint = getFramePosition(refs.memoized.focusable)
    if (this.chrome) {
      this.wrapperRect = this.chrome.positionable.getBoundingClientRect()
      this.bodyRect = this.chrome.positionable.ownerDocument.body.parentElement.getBoundingClientRect()
    }
  }

  onMeasure: FEAASEditorImports.EditorContext['onChromeMeasure'] = () => {}
  onPosition: FEAASEditorImports.EditorContext['onChromePosition'] = ({ focusable, root }) => {
    if (!this.chrome || this.props.hidden) return
    if (focusable) {
      const topbarHeight = 64
      const padding = 16
      this.chrome.wrapper.style.width = focusable.width + padding * 2 + 'px'
      this.chrome.wrapper.style.height = focusable.height + padding * 1 + topbarHeight + 'px'
      this.chrome.wrapper.style.left = focusable.left - padding + 'px'
      this.chrome.wrapper.style.top = focusable.top - topbarHeight + 'px'
      this.chrome.wrapper.style.opacity = '1'
      this.chrome.absolute = this.querySelector('.ui.overlays')
      if (this.chrome.absolute) {
        this.chrome.absolute.style.left = root.left + 'px'
        this.chrome.absolute.style.top = root.top + 'px'
      }
    } else {
      this.chrome.wrapper.style.opacity = '0'
      this.chrome.wrapper.style.left = '-1000px'
      this.chrome.wrapper.style.top = '-1000px'
      // wrapper.style.left = '-1000px'
      // wrapper.style.top = '-1000px'
    }

    //    this.setPlaceholder()
  }

  onWindowMessage = (event: MessageEvent) => {
    switch (event.data?.type) {
      case 'feaasEdit':
        const placeholder = this.getPlaceholder()
        if (this.setTarget(placeholder.editable, event.data)) {
          this.loader = placeholder.loader
          document.body.appendChild(placeholder.clipper)
          this.editedUID = event.data.id
          this.setPlaceholder()
          this.open()
        }
        break
      case 'feaasMeasurements':
        this.measurements = event.data.measurements
        if (!this.props.hidden) this.setPlaceholder()
        break
    }
  }

  connectedCallback(): void {
    super.connectedCallback()

    window.addEventListener('message', this.onWindowMessage)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()

    window.removeEventListener('message', this.onWindowMessage)
  }

  onContentChange = () => {
    this.setAttribute('changed', 'changed')
    requestAnimationFrame(() => {
      this.setIframeComponentContent()
    })
  }

  iframe: HTMLIFrameElement
  setIframe(iframe: HTMLIFrameElement) {
    this.iframe = iframe
  }

  setIframeComponentContent() {
    if (this.placeholder && this.iframe && this.placeholder.editable.innerHTML)
      this.iframe.contentWindow.postMessage({
        type: 'feaasUpdate',
        id: this.editedUID,
        content: this.placeholder.editable.innerHTML
      })
  }

  setPlaceholder() {
    if (!this.iframe) return
    const x = this.iframe.clientWidth
    const y = this.iframe.clientHeight
    const rect = this.iframe.getBoundingClientRect()
    const placeholder = this.getPlaceholder()
    const body = document.body.getBoundingClientRect()
    const measurement = this.measurements[this.editedUID]
    if (!measurement) return
    Object.assign(placeholder.clipper.style, {
      position: 'absolute',
      top: rect.top + this.iframe.clientTop - body.top + 'px',
      left: rect.left + this.iframe.clientLeft - body.left + 'px',
      width: x + 'px',
      height: y + 'px',
      pointerEvents: 'none',
      zIndex: 1,
      overflow: 'hidden'
    })
    Object.assign(placeholder.positionable.style, {
      position: 'absolute',
      top: measurement.top + 'px',
      left: measurement.left + 'px',
      width: measurement.width + 'px',
      zIndex: 2,
      transition: 'opacity 0.4s',
      //height: measurement.height + 'px',
      pointerEvents: this.props.hidden ? 'none' : 'all'
      //outline: '3px solid orange'
    })
    Object.assign(placeholder.loader.style, {
      position: 'absolute',
      top: measurement.top + 'px',
      left: measurement.left + 'px',
      width: measurement.width + 'px',
      height: measurement.height + 'px',
      filter: 'opacity(0.6)',
      zIndex: 3,
      pointerEvents: 'none'
      //outline: '3px solid orange'
    })
    document.dispatchEvent(new Event('feaasRefresh', { bubbles: true }))
  }

  chrome: {
    positionable: HTMLDivElement
    absolute: HTMLDivElement
    wrapper: HTMLDivElement
  }

  measurements: Record<string, DOMRect>
  editedUID: string
  placeholder: {
    clipper: HTMLDivElement
    positionable: HTMLDivElement
    editable: HTMLDivElement
    loader: FEAASLoader
  }
  loader: FEAASLoader
  getPlaceholder() {
    if (this.placeholder) return this.placeholder
    this.placeholder = {
      clipper: document.createElement('div'),
      positionable: document.createElement('div'),
      editable: Object.assign(document.createElement('div'), { className: '-feaas' }),
      loader: document.createElement('feaas-loader') as FEAASLoader
    }
    this.placeholder.clipper.appendChild(this.placeholder.positionable)
    this.placeholder.positionable.appendChild(this.placeholder.editable)
    this.placeholder.clipper.appendChild(this.placeholder.loader)
    return this.placeholder
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return
    if (name == 'hidden') {
      this.style.setProperty('display', 'block', 'important')
      this.style.setProperty('transition', 'opacity 0.3s', 'important')
      if (newValue) {
        this.style.setProperty('opacity', '0', 'important')
        this.style.pointerEvents = 'none'
      } else {
        this.style.setProperty('opacity', '1', 'important')
        this.style.pointerEvents = 'all'
      }
    }
    super.attributeChangedCallback(name, oldValue, newValue)
  }

  open() {
    clearTimeout(this.closing)
    this.removeAttribute('hidden')
    this.removeAttribute('changed')
    this.setLoadingState()
  }

  shouldFork(version: FEAASEditorImports.Model.VersionModel) {
    return version.forkOriginId == null && this.props.instance != null
  }

  cleanup() {
    if (this.placeholder) {
      this.placeholder.clipper?.remove()
      this.placeholder = null
    }
  }
  onClose = () => {
    this.setAttribute('hidden', 'hidden')
    if (this.placeholder) {
      this.placeholder.positionable.style.opacity = '0'
      this.placeholder.positionable.style.pointerEvents = 'none'
    }
    if (this.chrome) {
      this.chrome.wrapper.style.opacity = '0'
    }
    // FIXME: Workaround for CKEditor not removing event listeners properly
    // Reported: https://github.com/ckeditor/ckeditor5/issues/13141
    // Have to recreate the whole component
    if (this.target?.parentNode) {
      this.target.outerHTML = this.target.outerHTML
    }
    this.setTarget(null, {
      component: null,
      version: null,
      instance: null,
      revision: null
    })
    this.setEditorContext({
      isFocused: false
    })
  }
  close() {
    console.log('Editor is not ready')
  }
  unfork() {
    console.log('Editor is not ready')
  }
  discard() {
    console.log('Editor is not ready')
  }
  save() {
    console.log('Editor is not ready')
  }
  versionContext: FEAASEditorImports.VersionContext
  setVersionContext(props: Partial<FEAASEditorImports.VersionContext>) {
    console.log('Editor is not ready')
  }
  onVersionStateChange = (
    versionContext: FEAASEditorImports.VersionContext,
    setVersionContext: (context: Partial<FEAASEditorImports.VersionContext>) => void
  ) => {
    this.versionContext = versionContext
    this.setVersionContext = setVersionContext
  }

  editorContext: FEAASEditorImports.EditorContext
  setEditorContext(props: Partial<FEAASEditorImports.EditorContext>) {
    console.log('Editor is not ready')
  }
  onEditorStateChange = (
    editorContext: FEAASEditorImports.EditorContext,
    setEditorContext: (context: Partial<FEAASEditorImports.EditorContext>) => void
  ) => {
    this.editorContext = editorContext
    this.setEditorContext = setEditorContext
    // On editor loaded
    if (editorContext.editor && this.props.state == 'loading' && !this.props.hidden) {
      this.setAttribute('state', 'ready')
      this.ownerDocument.fonts.ready.then(() =>
        setTimeout(() => {
          this.setReadyState()
          this.setIframeComponentContent()
        }, 100)
      )
    }
  }

  setLoadingState() {
    this.showLoader()
    this.setAttribute('state', 'loading')
  }

  setReadyState() {
    this.hideLoader()
    this.setEditorContext({
      activeVersionId: this.component.versions[0].id
    })
    this.setAttribute('state', 'ready')
  }

  component: FEAASEditorImports.Model.ComponentModel
  version: FEAASEditorImports.Model.VersionModel
  closing: ReturnType<typeof setTimeout>

  render(
    {
      children,
      component: componentId,
      version: versionId,
      instance: instanceId
    }: React.PropsWithChildren<FEAASEditorProps>,
    {
      Editor,
      VersionStatusList,
      VersionStatusButton,
      Version,
      VersionMenu,
      EditorUIUndo,
      EditorUIMode,
      React: { useEffect, useMemo, useState, useContext },
      Chakra: { HStack, Box, Button, MenuItem },
      Chrome,
      ReactDOM,
      useSDK,
      useLibrary,
      useSlots,
      useModelObserver,
      ConfirmationContext,
      VersionContext
    }: typeof FEAASEditorImports
  ) {
    if (!this.payload) return
    const { React } = this.payload
    const sdk = useSDK()
    const library = useLibrary()
    const [component, setComponent] = useState<FEAASEditorImports.Model.ComponentModel>()
    const { setConfirm } = useContext(ConfirmationContext)
    const usedId = instanceId || versionId

    if (component) this.component = component

    useEffect(() => {
      if (this.props.hidden) return
      const comp = library.components.getItem(componentId).clone()
      comp.versions
        .search({ ids: [versionId, instanceId].filter(Boolean), componentIds: [componentId] })
        .then((versions) => {
          comp.versions.setItems(versions)
          const fork = comp.findVersion(instanceId, ['staged', 'published'])
          const original = comp.findVersion(versionId, ['staged', 'published'])
          if (fork) {
            if (fork.deletedAt) {
              // Get unforked version up to date
              fork.getDraft().changeAndNotify(original.toProperties()).restore() //.publish()
              console.error('FEAAS Editor: Restoring fork')
            } else {
              console.error('FEAAS Editor: Pre-forked')
              this.setAttribute('forked', fork.id)
            }
          } else {
            this.removeAttribute('forked')
            if (this.shouldFork(original)) {
              console.error('FEAAS Editor: Forking')
              const newForkId = original.fork(instanceId).id
              this.setAttribute('instance', newForkId)
            } else {
              console.error('FEAAS Editor: Regular')
            }
          }
          setComponent(comp)
        })
      return () => {
        this.closing = setTimeout(() => {
          this.closing = null
          this.cleanup()
          setComponent(null)
        }, 400)
      }
    }, [versionId, this.props.hidden])

    const versions = useModelObserver(component?.versions, []).filter((v) => v.id == usedId)
    const version = versions[0]
    if (version && !this.props.hidden) this.version = version

    useEffect(() => {
      this.unfork = () => {
        setConfirm({
          title: 'Reverting component customizations',
          button: 'Discard',
          body: 'Are you sure to reset component to its original state? All changes will be lost.',
          action: () => {
            const original = component.findVersion(versionId, ['staged', 'published'])
            component.findVersion(instanceId).unstage().unpublish().changeAndNotify(original.toProperties())
            component.saveVersions(false)
            this.setVersionContext({
              needsWrite: true
            })
            this.removeAttribute('forked')
            requestAnimationFrame(this.onClose)
          }
        })
      }
      this.discard = () => {
        if (this.props.changed) {
          component.findVersion(usedId).revert()
          component.saveVersions(false)
          this.setVersionContext({
            needsWrite: true
          })
        }
      }
      this.close = () => {
        setConfirm({
          title: 'Component has unsaved changes',
          button: 'Discard',
          body: 'Are you sure to close editor and discard changes?',
          bypass: !this.props.changed,
          action: () => {
            this.discard()
            requestAnimationFrame(this.onClose)
          }
        })
      }
      this.save = () => {
        setConfirm({
          bypass: !!this.props.forked,
          title: 'Customizing component',
          button: 'Save',
          body: 'Changes to the component will only be visible on that page. Changes to component done in Component Builder will not be reflected here. It will be possible to revert the customization.',
          action: () => {
            component
              .findVersion(usedId)
              .commitData({
                view: this.editorContext.editor.getData({ rootName: usedId }),
                model: ''
              })
              ?.stage()
              ?.publish()
            this.removeAttribute('changed')
            this.setAttribute('forked', usedId)
            component.saveVersions(false)
          }
        })
      }
      return () => {
        delete this.unfork
        delete this.discard
        delete this.save
        delete this.editorContext
        delete this.versionContext
        delete this.setEditorContext
        delete this.setVersionContext
      }
    }, [component])

    const target = useMemo(() => {
      return this.target
    }, [component])
    return useMemo(
      () =>
        component &&
        useSlots<any, 'picker' | 'children'>(children, ({ picker, children }) => (
          <Box
            ref={(el) => {
              this.chrome = el
                ? {
                    positionable: el,
                    wrapper: el.querySelector('.editor-chrome'),
                    absolute: this.querySelector('.ui.overlays')
                  }
                : null
            }}
          >
            <Box className='editor-wrapper' position='absolute' left={0} top={0}>
              <Editor
                datasources={sdk.datasources}
                sdk={sdk}
                library={library}
                component={component}
                versions={versions}
                context={{
                  isAutosaveEnabled: false,
                  onChromeMeasure: this.onMeasure,
                  onChromeBeforeMeasure: this.onBeforeMeasure,
                  onChromeMeasureElement: this.onChromeMeasureElement,
                  onChromePosition: this.onPosition,
                  onContentChange: this.onContentChange
                }}
              >
                {{
                  exports: this.onEditorStateChange,
                  after: children,
                  chrome: ({ editor }) => editor && <Chrome />,
                  children: ({ isArchivedDisplayed, editor, status }) => (
                    <Box
                      position='absolute'
                      left={0}
                      top={0}
                      className='editor-chrome'
                      p={4}
                      boxShadow={'2xl'}
                      pointerEvents='none'
                      opacity={0}
                      transition='opacity 0.3s'
                    >
                      <Box
                        left={0}
                        width='4'
                        top={0}
                        bottom={0}
                        backdropFilter='blur(2px)'
                        background='rgb(244 244 244 / 80%)'
                        position='absolute'
                      ></Box>
                      <Box
                        right={0}
                        width='4'
                        top={0}
                        bottom={0}
                        backdropFilter='blur(2px)'
                        background='rgb(244 244 244 / 80%)'
                        position='absolute'
                      ></Box>
                      <Box
                        top={0}
                        height='16'
                        left={0}
                        right={0}
                        backdropFilter='blur(2px)'
                        background='rgb(244 244 244 / 80%)'
                        position='absolute'
                      ></Box>
                      <Box
                        bottom={0}
                        height='4'
                        left={0}
                        right={0}
                        backdropFilter='blur(2px)'
                        background='rgb(244 244 244 / 80%)'
                        position='absolute'
                      ></Box>
                      {version && (
                        <Version
                          version={version}
                          node={target}
                          ignoreBreakpoints={true}
                          deps={[this.props.forked, this.props.changed]}
                        >
                          {{
                            exports: this.onVersionStateChange,
                            left: (
                              <HStack justifyContent='space-between'>
                                <EditorUIUndo size='sm' editor={status == 'ready' ? editor : null} />
                                <EditorUIMode size='sm' />
                              </HStack>
                            ),
                            middle: (versionContext, setVersionContext) => [
                              false && (
                                <VersionContext.Provider
                                  value={[
                                    {
                                      ...versionContext,
                                      version: component.findVersion(versionId)
                                    },
                                    setVersionContext
                                  ]}
                                >
                                  <VersionStatusList></VersionStatusList>
                                </VersionContext.Provider>
                              )
                            ],
                            right: (
                              <>
                                <Button variant='secondary' size='sm' onClick={this.close}>
                                  Close
                                </Button>
                                <Button
                                  variant='primary'
                                  size='sm'
                                  onClick={this.save}
                                  isDisabled={!this.props.changed}
                                >
                                  {!this.props.changed
                                    ? 'Up to date'
                                    : this.props.forked
                                    ? 'Save'
                                    : 'Save customization'}
                                </Button>
                                <VersionMenu isDisabled={!this.props.forked && !this.props.changed}>
                                  <MenuItem onClick={this.unfork} isDisabled={!this.props.forked}>
                                    Unfork
                                  </MenuItem>
                                  <MenuItem onClick={this.discard} isDisabled={!this.props.changed}>
                                    Discard
                                  </MenuItem>
                                </VersionMenu>
                              </>
                            )
                          }}
                        </Version>
                      )}
                    </Box>
                  )
                }}
              </Editor>
            </Box>
          </Box>
        )),
      [version, this.props.forked, this.props.changed]
    )
  }

  isReadyToLoad(): boolean {
    return this.props.component != null && this.props.version != null && !this.props.hidden
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-editor': OptionalExcept<FEAASEditorProps, 'component' | 'version'>
    }
  }
}
FEAASEditor.register('feaas-editor')
