export function observeDOMElement(element: HTMLElement) {
  const observer = new element.ownerDocument.defaultView.MutationObserver((changes) => {
    changes.forEach((change) => {
      const target = change.target as HTMLElement
      const src = target.getAttribute('data-embed-src')
      if (src) {
        loadScript(target, src)
      }

      if (change.addedNodes) {
        change.addedNodes.forEach((node) => {
          if (node.nodeType == 1) autoloadScripts(node as HTMLElement, false)
        })
      }
    })
  })
  observer.observe(element, { attributes: true, subtree: true, childList: true })
  return observer
}

export function autoloadScripts(element: HTMLElement, observe = true) {
  const embeds = element.querySelectorAll('[data-embed-src]')
  for (var i = 0; i < embeds.length; i++) {
    const embed = embeds[i]
    loadScript(embed as HTMLElement, embed.getAttribute('data-embed-src'))
  }
  if (observe) return observeDOMElement(element)
}

export function loadScript(element: HTMLElement, src: string) {
  for (var p = element; p; p = p.parentElement as HTMLElement) {
    if (!p.parentElement) {
      const scripts = p.querySelectorAll('script')
      for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') == src) {
          return true
        }
      }
      if (p.tagName == 'HTML') p = p.querySelector('head') || p
      const script = p.ownerDocument.createElement('script')
      script.setAttribute('type', 'module')
      script.setAttribute('src', src)
      p.appendChild(script)
      break
    }
  }
}
