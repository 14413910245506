export const parseLimit = (qualifier: string) => {
  const limit = parseInt(qualifier?.match(/\[0:(\d+)\]/)?.[1], 10)
  if (!isNaN(limit) && limit > 0) return String(limit)
  return null
}

export const parseRange = (qualifier: string) => {
  const bits = qualifier
    ?.match(/\[([^\]]+)\]/)?.[1]
    .split(/\s*,\s*/g)
    .filter(Boolean)
    .map((bit) => {
      // individual indexes
      if (bit.match(/^-?\d+$/)) return bit
      // ranges
      const range = bit.match(/^(\d+):(\d+)$/)
      return range && parseInt(range[1], 10) < parseInt(range[2], 10) ? range[1] + '-' + range[2] : null
    })
  if (!bits?.every(Boolean)) return null
  return bits.join(',')
}

export const getQualifier = (jsonpath: string) => jsonpath?.match(/(\.\*|\[[^\]]*\])$/)?.[0]

export function cleanCollectionBit(path: string) {
  return path.replace(/(\.?\[[^\]\[]*\]|\.\*)$/g, '')
}

export function normalizeCollectionScope(path: string) {
  return cleanCollectionBit(path) + '.*'
}

// increment numbers by given numbers, exclude second value of ranges to ensure inclusivity
export function adjustNumbers(string: string, diff: number) {
  return string?.replace(/(.|^)(\d+)/g, (m, b, n) => b + (b == ':' ? n : String(parseInt(n) + diff)))
}

export function getHumanizedLabel(path: string, maxBits = 2) {
  var bits = cleanCollectionBit(path)
    .split(/]\.|\.\*\./g)
    .pop()
    .split(/\./g)
    .slice(-3)
  bits = bits.filter((b) => isNaN(parseInt(b)))
  return bits
    .map((bit) => bit.charAt(0).toUpperCase() + bit.slice(1))
    .slice(-maxBits)
    .join(' ')
}

export function getLastPathBit(path: string) {
  return cleanCollectionBit(path).split('.').pop()
}
