import type { SDK, Style, VersionModel, ComponentModel, StylesheetModel } from '@sitecore-feaas/sdk'
import { fetchAndRevalidate } from '../fetch.js'
import { renderComponent } from './component.js'

export type Callback = (image: HTMLImageElement | null, isCached: boolean) => void

export const convertBase64toBlob = (base64: string) => fetch(base64).then((res) => res.blob())

/** Parse fetch response as blob and turn it into HTMLImageElement off thread */
export const responseToImage = (response: Response): Promise<HTMLImageElement> =>
  response.blob().then((blob) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onerror = reject
      img.onload = () => {
        resolve(img)
      }
      img.src = URL.createObjectURL(blob)
    })
  })

/**
 * Retrieves the version of a given component that best fits a specific display width.
 *
 * The function ensures that the version data for the component is available and selects the one that is most suitable
 * for a display width of 800 pixels.
 *
 * @param {ComponentModel} component  - The component for which the version needs to be obtained.
 * @returns {Promise<VersionModel>} - A promise that resolves to the most suitable version for a display width of 800
 *                                  pixels.
 */
export async function getVersionForThumbnail(component: ComponentModel): Promise<VersionModel> {
  if (!component.versions.length) await component.versions.fetch()
  return component.getVersionForWidth(800)
}

/**
 * Ensures an up-to-date thumbnail for a specific component and version.
 *
 * This operation initiates thumbnail load and, if that fails or if file is out of date, a new thumbnail is generated.
 * It can work with a callback, which is designed to potentially fire twice for enhancing user experience. This dual
 * firing allows for an immediate display of a possibly stale cached image while downloading or generating new version
 * in the background.
 *
 * 1. The first call can happen with the stale cached or outdated image.
 * 2. The second call occurs with the up-to-date loaded or freshly generated generated image.
 *
 * @param {ComponentModel} component   - Relevant component.
 * @param {VersionModel}   [version]   - Relevant version (optional, falls back to responsive).
 * @param {Callback}       [callback]  - Callback to handle the loaded or generated image (optional).
 * @returns {Promise<HTMLImageElement | null>} - The final thumbnail image (either loaded or freshly generated).
 */
export async function get(component: ComponentModel, version?: VersionModel, callback?: Callback) {
  try {
    if (!component.isNew) {
      return await load(component, version, callback)
    }
  } catch (e) {
    component.sdk.log(
      `FEAAS: Thumbnail ${component.id}, version: ${
        version?.id || 'responsive'
      }, status: generating ${status}, reason: ${e}`
    )
  }
  // generate the image
  const img = await generate(component, version)
  callback?.(img, false)

  // upload base64 image as blob off-thread
  if (img && !component.isNew) {
    convertBase64toBlob(img.src).then((blob) => upload(component, version, blob))
  }
  return img
}

/**
 * Retrieves the thumbnail image for a given component/version combination, with a responsive default if version is not
 * provided.
 *
 * The resulting HTML <img> element will have `width`, `height`, and `src` properties ready for use. Although it can
 * operate as a regular promise that resolves when the thumbnail is loaded, it also supports a callback for an enhanced
 * user experience. This callback may be invoked twice, depending on the state of the cache:
 *
 * 1. The first call occurs if the image was previously cached by the browser, but the cache is stale.
 * 2. The second call happens when the image is updated from a fresh download. By this design, the user gets to see a
 * cached image immediately while the updated image is being downloaded in the background.
 *
 * If the version of a component is known to be newer than remote file, the function will throw `InvalidResponse` error.
 * As a way to handle that error, the `generate` function may be used to produce new thumbnail (@see render).
 *
 * @param {ComponentModel} component   - The component to load a thumbnail for.
 * @param {VersionModel}   [version]   - The version of the component to load a thumbnail for. If not provided, a
 *                                     version suitable for a responsive component is selected.
 * @param {Callback}       [callback]  - A callback to handle the loaded image. This callback may be invoked twice for
 *                                     the reasons described above.
 * @returns {Promise<HTMLImageElement>} - The thumbnail image for the component/version combination.
 */
export async function load(
  component: ComponentModel,
  version?: VersionModel,
  callback?: Callback
): Promise<HTMLImageElement> {
  const url = version ? version.getThumbnailURL() : component.getThumbnailURL()
  const stylesheet = component.library.stylesheet
  const lastModifiedDate = new Date(Math.max(Number(component.modifiedAt), Number(stylesheet.modifiedAt)))
  component.sdk.log(`FEAAS: Thumbnail ${component.id}/${version?.id || 'responsive'}, loading`)
  // Otherwise use a logic that will invoke callback for cached request and then try to revalidate it
  var img: HTMLImageElement
  await fetchAndRevalidate(
    url,
    {},
    (response, isCached) => {
      // <2 second difference is not considered outdated
      // If thumbnail is detected as outdated it will throw error and promise will be rejected
      const isUpToDate =
        Math.abs(Number(new Date(response.headers.get('x-ms-meta-componentRenderedAt'))) - Number(lastModifiedDate)) <
        2000

      if (isCached && !isUpToDate) {
        responseToImage(response).then((image) => {
          img = image
          callback(image, isCached)
        })
      }
      return isUpToDate
    },
    (response, state, isCached) => {
      component.sdk.log(`FEAAS: Thumbnail ${component.id}/${version?.id || 'responsive'}, status: ${state}`)
      responseToImage(response).then((image) => {
        img = image
        callback(image, isCached)
      })
    },
    null
  )
  return img
}

/**
 * Creates a thumbnail image for a given component and version.
 *
 * First, it determines the appropriate version to use. If not provided, it attempts to fetch one suitable for
 * thumbnail. It then leverages `render` to generate an image with provided parameters and finally, if the component
 * isn't new, uploads the generated as a Blob onto cdn for future retrieval.
 *
 * @param {ComponentModel} component  - Component instance.
 * @param {VersionModel}   [version]  - Version instance.
 * @returns {Promise<HTMLImageElement | null>} Thumbnail image or null if generation isn't possible.
 */
export async function generate(component: ComponentModel, version?: VersionModel, customStylesheet?: StylesheetModel) {
  const sourceVersion = version || (component.isNew ? null : await getVersionForThumbnail(component))

  if (!sourceVersion || sourceVersion.isViewEmpty()) {
    return null
  }
  const stylesheet = customStylesheet || component.library.stylesheets.first

  const { datasources } = component.sdk

  const img = await render(
    sourceVersion.classList,
    stylesheet.getBreakpointForWidth(800, sourceVersion.getBreakpoints()),
    sourceVersion.view,
    datasources.reduce((object, datasource) => {
      return Object.assign(object, { [datasource.id]: datasource.sample })
    }, {}),
    stylesheet.css,
    component.sdk
  )

  return img
}

/**
 * Transforms a specific component version into an image.
 *
 * Generates an isolated environment (iframe) to render the component with supplied parameters. 'html2canvas.js' is then
 * injected into the iframe to capture the rendered component as an image. The image is created respecting the provided
 * design breakpoint, ensuring an accurate visual representation.
 *
 * @param {string[]}                 classList   - Array of class names applied to the rendered component.
 * @param {Style.Rule<'breakpoint'>} breakpoint  - Breakpoint style rule that dictates the viewport dimensions for
 *                                               rendering.
 * @param {string}                   template    - String template of the component to render.
 * @param {any}                      data        - Data object to be used when rendering the component.
 * @param {string}                   cssText     - CSS rules to be applied during component rendering.
 * @param {EnvironmentVariables}     env         - Environment variables used to set frontend and backend endpoint URLs.
 * @returns {Promise<BasicImage>} - Promise that resolves to an object containing the src, width, and height of the
 *                                created image.
 */
function render(
  classList: string[],
  breakpoint: Style.Rule<'breakpoint'>,
  template: string,
  data: any,
  cssText: string,
  sdk: SDK
) {
  const iframe = document.createElement('iframe')
  iframe.style.position = 'absolute'
  iframe.style.top = '-2000px'
  iframe.style.left = '-2000px'
  iframe.style.width = breakpoint.props.minWidth + 'px'
  iframe.style.height = '720px'
  iframe.style.zIndex = '100011'
  document.body.appendChild(iframe)

  return new Promise<HTMLImageElement>((resolve, reject) => {
    const script = document.createElement('script')
    script.onerror = reject
    script.src = sdk.frontend + '/assets/html2canvas.js'
    script.onload = () => {
      ;(iframe.contentWindow.document.fonts?.ready || Promise.resolve()).then(function () {
        return (
          iframe.contentWindow
            //@ts-ignore
            .html2canvas(root, {
              // 1.1 scale is to remove shadows that are drawn incorrectly
              scale: Math.max(1.1, 1000 / Math.min(1401, Math.max(320, breakpoint.props.minWidth))),
              width: Math.min(1401, Math.max(320, breakpoint.props.minWidth)),
              height: Math.max(1, Math.min(2000, root.offsetHeight)),
              windowWidth: breakpoint.props.minWidth + 'px',
              windowHeight: 1000,
              proxy: sdk.backend + '/proxy/media',
              backgroundColor: '#ffffff'
            })
            .then((canvas: HTMLCanvasElement) => {
              const src = canvas.toDataURL('image/jpeg', 0.6)
              iframe.parentElement.removeChild(iframe)
              const img = new Image()
              img.onload = () => {
                resolve(img)
              }
              img.onerror = reject
              img.src = src
            })
        )
      })
    }
    iframe.contentWindow.document.head.appendChild(script)
    const stylesheet = iframe.contentWindow.document.createElement('style')
    stylesheet.textContent = `
      ${cssText}
      body, html { padding: 0; margin: 0; overflow: hidden }
    `
    const stylesheet2 = iframe.contentWindow.document.createElement('style')
    stylesheet2.textContent = `
      body, html { padding: 0; margin: 0; overflow: hidden }
    `

    iframe.contentWindow.document.head.appendChild(stylesheet)
    iframe.contentWindow.document.head.appendChild(stylesheet2)
    const root = renderComponent({ template, data })
    root.classList.add(...classList, '-feaas--preview', '-emulate--' + breakpoint.details.slug)
    iframe.contentWindow.document.body.appendChild(root)
  })
}

/**
 * Uploads a thumbnail Blob to a CDN, enabling it for future retrieval.
 *
 * It constructs a URL based on component and version information, if available. The thumbnail's blob is uploaded with
 * several metadata points, including identifiers and timestamps. Upon successful upload, it sends a no-cache fetch
 * request to the constructed URL to bust the cache.
 *
 * @param {ComponentModel} component  - Component instance.
 * @param {VersionModel}   version    - Version instance.
 * @param {Blob}           blob       - Blob instance to upload.
 * @returns {Promise<string>} URL of the uploaded Blob.
 */
export async function upload(component: ComponentModel, version: VersionModel, blob: Blob): Promise<string> {
  const url = version ? version.getThumbnailURL() : component.getThumbnailURL()
  const lastModifiedDate = new Date(
    Math.max(Number(component.modifiedAt), Number(component.library.stylesheet.modifiedAt))
  )

  try {
    await component.sdk.uploadBlob('thumbnails', url.split(/\/thumbnails\//)[1], blob, {
      headers: {
        'x-ms-blob-content-type': 'image/jpg',
        'x-ms-blob-cache-control': 'public,max-age=31536000,immutable',
        'x-ms-meta-versionId': version ? version.id : null,
        'x-ms-meta-versionRevision': version ? version.revision.toString() : null,
        'x-ms-meta-componentId': component.id,
        'x-ms-meta-componentRenderedAt': lastModifiedDate.toUTCString(),
        'x-ms-meta-componentModifiedAt': component.modifiedAt.toUTCString(),
        'x-ms-meta-componentCreatedAt': component.createdAt.toUTCString(),
        'x-ms-meta-modifiedAt': component.modifiedAt.toUTCString()
      }
    })
    // bust thumbnail cache for the future, off-thread
    fetch(url, {
      cache: 'no-cache'
    }).then(() => {
      component.sdk.log('FEAAS: Thumbnail uploaded', url)
    })
  } catch (e) {
    component.sdk.log('FEAAS: Thumbnail failed to upload', url, e)
  }

  return url
}
