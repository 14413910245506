import { FEAASCDNComponentProps } from '../cdn.js'
import { queryScopes, serializeCH1RichText } from '../utils/data.js'
import { Data, DataScopes } from '../utils/settings.js'
import { parseHTML } from './html.js'
import { loadScript } from './scripts.js'
import { normalizeCollectionScope } from '../utils/datapath.js'
import { DataOptions } from '../headless.js'
import { isDate, formatDate } from '../utils/date.js'

export interface FEAASCustomizations {
  processTemplate?: (element: HTMLElement, template: string, data: DataScopes) => string
  processData?: (element: HTMLElement, data: DataScopes) => any
  processValue?: (element: HTMLElement, property: string, value: any) => any
  shouldRepeat?: (element: HTMLElement, value: any) => boolean
  onRender?: (element: HTMLElement, data: DataScopes) => void
}

export interface ExplicitTemplate {
  template: string
}

export type FEAASComponentProps<T = DataOptions> = Partial<ExplicitTemplate> & {
  data?: T
} & (ExplicitTemplate | FEAASCDNComponentProps) &
  FEAASCustomizations

function getDOMCollectionIndex(element: HTMLElement) {
  const datascope = element.getAttribute('data-path-scope')
  var index = 0
  for (var p = element; (p = <HTMLElement>p.previousElementSibling); ) {
    if (p.getAttribute('data-path-scope') == datascope) {
      index++
    }
  }
  return index
}

function updateDOMClones(element: HTMLElement, targetCount: number, config?: FEAASCustomizations) {
  const datascope = element.getAttribute('data-path-scope')
  if (datascope == null) {
    return
  }
  var index = getDOMCollectionIndex(element)
  if (index != 0) {
    return
  }

  const parentElement = element.parentNode as HTMLElement
  if (parentElement == null) {
    return
  }

  /*dont remove first element, just hide it*/
  if (targetCount == 0) {
    element.setAttribute('hidden', 'hidden')
  } else if (element.getAttribute('hidden') != null) {
    element.removeAttribute('hidden')
  }

  var count = index + 1
  var last = element
  for (var n = element; (n = <HTMLElement>n.nextElementSibling); ) {
    if (n.getAttribute('data-path-scope') == datascope) {
      count++
      last = n
    }
  }
  /* add missing clones */
  for (var i = count; i < targetCount; i++) {
    parentElement.insertBefore(last.cloneNode(true), last.nextElementSibling)
  }
  /* remove excessive clones fron previous renders */
  for (var i = Math.max(1, targetCount); i < count; i++) {
    last = <HTMLElement>last.previousElementSibling
    parentElement.removeChild(<HTMLElement>last.nextElementSibling)
  }
}

export function setDOMAttribute(element: HTMLElement, attribute: string, value: any, config?: FEAASCustomizations) {
  if (!element) return
  var target = element
  if (value?.type == 'doc' && Array.isArray(value.content)) value = serializeCH1RichText(value)
  if (config?.processValue) {
    value = config?.processValue(element, attribute, value)
  }
  var prop = attribute.replace('data-path-', '')
  if (attribute == 'data-path-src' || attribute == 'data-path-alt') {
    const picture = Array.from(element.children).find((n) => n.localName == 'picture') || element
    target = element.localName == 'img' ? element : <HTMLElement>picture.querySelector('img, video')
  } else if (attribute == 'data-path-href') {
    target = element.querySelector('a') || element.closest('a')
  } else if (attribute == 'data-path-hidden') {
    value = !value ? true : null
  } else if (attribute == 'data-path' || attribute == 'data-embed-html' || attribute == 'html') {
    prop = 'innerHTML'
  } else if (attribute == 'data-embed-src') {
    loadScript(element, value)
  } else if (attribute == 'data-path-attributes') {
    if (!Array.isArray(value)) {
      const oldKeys = element.getAttribute('data-attributes-keys')?.split(',').filter(Boolean) || []
      if (!Array.isArray(value))
        for (var property in value) {
          var subvalue = value[property]
          setDOMAttribute(
            element,
            property,
            typeof subvalue == 'object' && subvalue ? JSON.stringify(subvalue) : subvalue,
            config
          )
        }
      for (var i = 0; i < oldKeys.length; i++) {
        if (value == null || !(oldKeys[i] in value)) {
          setDOMAttribute(element, oldKeys[i], null, config)
        }
      }
      setDOMAttribute(element, 'data-attributes-keys', Object.keys(value || {}).join(','))
    }
    // BYOC components also accept their data object in datasources attribute.
    if (element.tagName == 'FEAAS-EXTERNAL') {
      const datasourceId =
        String(element.getAttribute('data-path-attributes') || '')
          .slice(0, -1)
          .split('.')[0] || '_'
      setDOMAttribute(element, 'datasources', JSON.stringify({ [datasourceId]: value }))
    }
    return
  }
  if (typeof value == 'boolean') {
    value = value ? '' : null
  }

  if (!target) return

  const usePlaceholder = prop == 'innerHTML' || prop == 'src'
  if (usePlaceholder && (value == null || value === '')) {
    var placeholder = String(
      (element.getAttribute('data-path-placeholder') || target[prop as keyof HTMLElement]) ??
        target.getAttribute(prop) ??
        ''
    )
    if (prop == 'innerHTML') {
      target.innerHTML = placeholder
    } else if (placeholder) {
      target.setAttribute(prop, placeholder)
    } else {
      target.removeAttribute(prop)
    }
  } else if (value != null) {
    if (prop == 'innerHTML') {
      var str = String(value)
      // sanitize html of structural text elements
      for (var parent = target; parent.parentElement && parent.localName.match(/^(a|var|strong|em|i|b)$/); )
        parent = parent.parentElement
      if (parent.localName.match(/p|h\d|button|time|li/)) {
        str = str
          .replace(/<(p|h\d|li|ul)[^>]*?>|<\/(p|h\d|li|ul)[^>]*?>/g, ' ')
          .trim()
          .replace(/\s+/g, ' ')
      }
      if (String(parent.getAttribute('class')).includes('-inline--time')) {
        if (isDate(str)) {
          str = formatDate(new Date(str))
        }
      }

      if (target.innerHTML != str) {
        target.innerHTML = str
      }
    } else {
      if (target.getAttribute(prop) != value) {
        target.setAttribute(prop, value)
      }
    }
  } else {
    target.removeAttribute(prop)
  }
}

// order in which attributes are proccesed. Rightmost are more important
var sortOrder = ['data-attributes-keys', 'data-path-attributes', 'data-path-scope']
export function renderDOMElement(
  input: HTMLElement | DocumentFragment,
  datascopes?: any,
  config?: FEAASCustomizations,
  isNested?: boolean
) {
  const element = <HTMLElement>input
  if (!isNested) {
    datascopes = config?.processData?.(element, datascopes) || datascopes
  }
  if (element.nodeType == 1) {
    const datascope = element.getAttribute('data-path-scope')
    if (datascope) {
      const index = getDOMCollectionIndex(element)
      const collection = queryScopes(datascopes, datascope, false)
      if (index == 0) {
        updateDOMClones(element, config?.shouldRepeat?.(element, collection) === false ? 1 : collection.length, config)
      }

      datascopes = {
        ...datascopes,
        [normalizeCollectionScope(datascope)]: collection[index]
      }
    }
    const attrs = Array.prototype.slice.call(element.attributes).sort((a: Attr, b: Attr) => {
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
    })
    for (var i = 0; i < attrs.length; i++) {
      const { name, value: datapath } = attrs[i]
      if (name.startsWith('data-path') && name != 'data-path-scope') {
        setDOMAttribute(
          element,
          name,
          queryScopes(datascopes, datapath, true, undefined, element.tagName != 'FEAAS-EXTERNAL'),
          config
        )
      }
      if (name == 'data-embed-src') {
        setDOMAttribute(element, name, element.getAttribute(name), config)
      }
    }
  }
  for (var i = 0, child: Node; (child = element.childNodes[i++]); ) {
    if (isElement(child)) {
      renderDOMElement(child, datascopes, config, true)
    }
  }
  if (!isNested) {
    config?.onRender?.(element, datascopes)
  }
  return element
}

function isElement(node: Node): node is HTMLElement {
  return node.nodeType == 1
}

export function renderDOMContent(root: HTMLElement, template: string, data?: Data, config?: FEAASCustomizations) {
  const newTemplate = config?.processTemplate?.(root, template, data) || template
  root.innerHTML = newTemplate
  root.classList.add('-feaas')
  // fixme, jss needs to assign theme classes to component instead
  const anchor = root.closest('.component.feaas') || root
  if (anchor.className.includes('-theme--')) {
    for (var i = 0, child: Node; (child = root.childNodes[i++]); ) {
      if (isElement(child)) {
        child.className = child.className.replace(/(\s|^)-theme--[^\s]+\s*/g, '$1')
      }
    }
  }
  return renderDOMElement(root, data, config)
}

export function renderHTMLContent(template: string, data?: Data, config?: FEAASCustomizations, fakeDOM = false) {
  const newTemplate = config?.processTemplate?.(null, template, data) || template
  if (typeof document === 'undefined' || fakeDOM) {
    const nodes = parseHTML(newTemplate)
    return renderDOMElement(nodes[0] as unknown as HTMLElement, data, config).outerHTML
  } else {
    const root = document.createElement('div')
    root.innerHTML = newTemplate
    renderDOMElement(root, data, config)
    return root.innerHTML
  }
}
