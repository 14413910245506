import type { ReactNode, ReactElement, JSXElementConstructor, PropsWithChildren } from 'react'
import { FEAASElement } from './FEAASElement.js'

export type ReactModules = {
  React: typeof React
  ReactDOM: typeof ReactDOM
}
export type ReactJSX = ReactNode | ReactElement<any, string | JSXElementConstructor<any>>
export type FEAASReactRender<P> = {
  (this: FEAASReactElement<P>, props: P, payload: ReactModules): ReactJSX
}

export default class FEAASReactElement<P> extends FEAASElement<P, ReactModules> {
  load() {
    return Promise.all([
      // @ts-ignore @webpack-ignore
      import('https://cdn.skypack.dev/react'),
      // @ts-ignore
      import('https://cdn.skypack.dev/react-dom')
    ]).then(([React, ReactDOM]) => {
      return { React, ReactDOM }
    })
  }

  render({}: P, { React }: ReactModules): ReactJSX {
    return <></>
  }
}

interface MyComponentProps {
  counter: string
}

export class MyComponent extends FEAASReactElement<MyComponentProps> {
  // will re-render component if any of these attributes have changed
  static observedAttributes = ['counter']

  useShadowRoot = true

  // parse attributes from web component to react-ready form
  getProps() {
    return {
      counter: parseFloat(this.getAttribute('counter'))
    }
  }

  // First argument is props, second arguments is React stuff
  // Destructuring React is important for JSX to work
  render({ counter }: MyComponentProps, { React }: ReactModules): ReactJSX {
    const [updates, setUpdates] = React.useState(0)
    React.useEffect(() => {
      setUpdates((u) => u + 1)
    }, [counter])
    return (
      <>
        <p>Rendering attribute: {counter}</p>
        <p>Triggered updates: {updates}</p>
      </>
    )
  }
}
