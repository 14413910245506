export type ControlledPromise<T> = Promise<T> & {
  resolve: (result?: T) => void
  reject: (e: Error) => void
  restart: () => ControlledPromise<T>
  first: ControlledPromise<T>
}

/**
 * ControlledPromise is a function that returns a promise with additional methods for resolve, reject, restart, and
 * first. This allows more granular control over the promise resolution process. It also avoids unhandled rejections by
 * catching any errors and passing them to the onFailure callback.
 *
 * @example
 *   const promise = ControlledPromise(
 *     (result) => console.log(result),
 *     (error) => console.error(error)
 *   )
 *   promise.resolve('Success') // logs: "Success"
 *   promise.reject(new Error('Failure')) // logs: Error: Failure
 *
 * @template T The type of value that the promise will resolve with.
 * @param {(result: T) => void} [onSuccess] - A callback function that is called when the promise is successfully
 *   resolved. The resolved value is passed to this function.
 * @param {(e: Error) => void} [onFailure=(e: Error) => {}] - A callback function that is called when the promise is
 *   rejected. The Error object is passed to this function. Default is `(e: Error) => {}`
 * @returns {ControlledPromise<T>} A ControlledPromise object with the following properties:
 *
 *   - Resolve: A function that when called, will resolve the promise.
 *   - Reject: A function that when called, will reject the promise with the provided error.
 *   - Restart: A function that when called, will restart the promise. This creates a new promise and replaces the current
 *       promise with the new one.
 *   - First: The first promise that was created by the restart function. This property is initially undefined, and is set
 *       when the restart function is called for the first time.
 */
export function ControlledPromise<T>(onSuccess?: (result: T) => void, onFailure = (e: Error) => {}) {
  var first: ControlledPromise<T>
  const restart = () => {
    var resolve: (result?: T) => void
    var reject: (e: Error) => void
    const promise = new Promise<T>((success, failure) => {
      resolve = (value: T) => {
        onSuccess?.(value)
        success(value)
      }
      reject = failure
    })
    var controlled: ControlledPromise<T> = Object.assign(promise, {
      resolve,
      reject,
      restart,
      first
    })
    first = controlled

    // Avoid unhandled rejection
    controlled.catch(onFailure)

    return controlled
  }
  return restart()
}
